import { db } from '../firebaseConfig';
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  limit,
  serverTimestamp,
  onSnapshot,
  QuerySnapshot,
  getDocs,
  orderBy,
  DocumentSnapshot,
  startAfter
} from 'firebase/firestore';
import { Case } from '../models/Case/case';

const collectionRef = collection(db, 'cases');


// Add a new case...This most likely won't be used by the front end
export const addCase = async (
  agentEmail: string
): Promise<Case> => {
  const newCase: Case = {
    agentEmail
  };

  try {
    const caseRef = doc(collectionRef, "uid");
    await setDoc(caseRef, newCase);
    return newCase;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

// Soft delete a case
export const deleteCase = async (caseId: string): Promise<void> => {
  try {
    const caseRef = doc(collectionRef, caseId);
    await updateDoc(caseRef, { isDeleted: true });
  } catch (error) {
    console.error("Error deleting document: ", error);
    throw error;
  }
};

// Edit a case
export const editCase = async (caseId: string, score: number): Promise<void> => {
  const updatedCase = {
    score: +score,
    lastUpdate: serverTimestamp(),
  };

  try {
    const caseRef = doc(collectionRef, caseId);
    await updateDoc(caseRef, updatedCase);
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

// Real-time listener for cases
export const onCasesSnapshot = (
  userEmail: string,
  showOrgCases: boolean,
  batchSize: number,
  callback: (snapshot: QuerySnapshot) => void
): () => void => {
  let q;

  if (showOrgCases) {
    const emailDomain = userEmail.substring(userEmail.indexOf('@'));

    q = query(
      collectionRef,
      where('isDeleted', '==', false),
      where('agentDomain', '==', emailDomain),
      orderBy('createdDate', 'desc'),
      limit(batchSize)
    );
  } else {
    q = query(
      collectionRef,
      where('isDeleted', '==', false),
      where('agentEmail', '==', userEmail),
      orderBy('createdDate', 'desc'),
      limit(batchSize)
    );
  }

  return onSnapshot(q, callback);
};

export const fetchCasesBatch = async (
  userEmail: string,
  showOrgCases: boolean,
  batchSize: number,
  lastVisibleDoc?: DocumentSnapshot
): Promise<QuerySnapshot> => {
  
  const emailDomain = userEmail.substring(userEmail.indexOf('@'));
  const baseQuery = query(
    collectionRef,
    where('isDeleted', '==', false),
    orderBy('createdDate', 'desc'),
    limit(batchSize)
  );

  // Modify query based on whether we’re showing organizational cases
  const finalQuery = showOrgCases
    ? query(baseQuery, where('agentDomain', '==', emailDomain))
    : query(baseQuery, where('agentEmail', '==', userEmail));

  // If fetching the next batch, start after the last visible document
  const paginatedQuery = lastVisibleDoc ? query(finalQuery, startAfter(lastVisibleDoc)) : finalQuery;

  // Perform a one-time fetch with getDocs
  return getDocs(paginatedQuery);
};

export const getCaseByAppId = async (appId: string): Promise<Case | null> => {
  try {
    const q = query(collectionRef, where("appId", "==", appId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const caseDoc = querySnapshot.docs[0];
      return caseDoc.data();
    } else {
      console.log("No case found with the given appId");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    throw error;
  }
};

export const adminFetchCasesBatch = async (
  batchSize: number,
  lastVisibleDoc?: DocumentSnapshot
): Promise<QuerySnapshot> => {
  const baseQuery = query(
    collectionRef,
    where('isDeleted', '==', false),
    orderBy('createdDate', 'desc'),
    limit(batchSize)
  );

  // If fetching the next batch, start after the last visible document
  const paginatedQuery = lastVisibleDoc ? query(baseQuery, startAfter(lastVisibleDoc)) : baseQuery;

  // Perform a one-time fetch with getDocs
  return getDocs(paginatedQuery);
};
