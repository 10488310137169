import { useEffect, useState } from 'react';
import { ExchangeRelay } from '../../models/exchangeRelay';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { fetchInquiriesByCaseUid } from '../../services/inquiry-data.service';
import { carrierContacts } from '../../types/carrierContacts';
import "./offerqualityreport.css";
import { getDefaultMonthYear, getShortDateFormat, months, years } from '../../utils/dateTime';
import { getCaseByAppId } from '../../services/case-data.service';

export type OfferQualityItem = {
  carrier?: string;
  applicant: string;
  policyId?: string;
  submitDate?: string;
  offerDate?: string;
  targetPremium?: string;
  faceAmount?: string;
  tentativeOffer?: string;
  placed?: boolean;
};

const OfferQualityReport = () => {
  const { defaultYear } = getDefaultMonthYear();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [exchangePartner, setExchangePartner] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [selectedYear, setSelectedYear] = useState<number>(defaultYear);
  const [offerQualityItems, setofferQualityItems] = useState<OfferQualityItem[] | null>(null);

  const { userData } = useAppSelector(selectAuth);

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    setSelectedMonth(value);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    setSelectedYear(value);
  };

  const formatDate = (dateTime: string | undefined): string => {
    if (!dateTime) return "";
    return dateTime.split(' ')[0]; // Extract the date portion
  };

  useEffect(() => {
    const fetchRecords = async () => {
      const carrierCodes = carrierContacts.filter(i => i.exchangePartner === exchangePartner).map((carrier) => carrier.code);
      if (selectedMonth === undefined || selectedYear === undefined || carrierCodes.length < 1) {
        return;
      }
  
      try {
        const month = selectedMonth + 1; // Adjust for 0-based index
        const year = selectedYear;
  
        const exchangeRelayRef = collection(db, "exchangeRelay");
        const q = query(
          exchangeRelayRef,
          where("carrierCode", "in", carrierCodes),
          where("month", "==", month),
          where("year", "==", year)
        );
  
        const querySnapshot = await getDocs(q);
  
        const offerQualityItems = await Promise.all(
          querySnapshot.docs.map(async (doc) => createInvoiceItem(doc.data()))
        );

        offerQualityItems.sort((a, b) => a.applicant.localeCompare(b.applicant))
  
        setofferQualityItems(offerQualityItems);
      } catch (error) {
        console.error("Error fetching records:", error);
      }
    };

    const createInvoiceItem = async (exchangeRelay: ExchangeRelay): Promise<OfferQualityItem> => {
      const carrier = carrierContacts.filter(carrierContact => carrierContact.code === exchangeRelay.carrierCode);
      const userDomain = parseEmail(userData?.email);
      const carrierDomain = parseEmail(exchangeRelay?.carrierEmail);
      const carrierDisplay = userDomain === carrierDomain ? carrier[0]?.name : '*****';

      const offerQualityItem: OfferQualityItem = {
        applicant: '',
        carrier: carrierDisplay || '',
        offerDate: exchangeRelay.offerDate ? getShortDateFormat(exchangeRelay.offerDate) : '',
        submitDate: formatDate(exchangeRelay.sendDate),
        policyId:  userDomain === carrierDomain ? exchangeRelay.carrierId : '',
        placed: exchangeRelay.placed,
        tentativeOffer: exchangeRelay.tentativeOffer
      };
    
      try {
        // Fetch caseData if appId exists
        if (exchangeRelay.appId) {
          const caseData = await getCaseByAppId(exchangeRelay.appId);
          if (caseData) {
            offerQualityItem.applicant = `${caseData.lastName}, ${caseData.firstName}`;
          }
        }
    
        // Fetch inquiries if caseUid exists
        if (exchangeRelay.caseUid) {
          const informalInquiries = await fetchInquiriesByCaseUid(exchangeRelay.caseUid);
          if (informalInquiries.length > 0) {
            const informalInquiry = informalInquiries[0];
            offerQualityItem.targetPremium = informalInquiry.targetPremium;
            offerQualityItem.faceAmount = informalInquiry.faceAmount;
          }
        }
      } catch (error) {
        setError('Error')
        console.error(`Error processing exchangeRelay with ID ${exchangeRelay.carrierId || "unknown"}:`, error);
      }
    
      return offerQualityItem;
    };

    setLoading(true);
    setExchangePartner(userData?.exchangePartner ?? "AIN");
    fetchRecords();
    setLoading(false);
  }, [exchangePartner, selectedMonth, selectedYear, userData]);


  const parseEmail = (email: string | undefined) => {
    const atIndex = email?.indexOf('@');
    if (atIndex === -1) {
      return ''; // Handle case where '@' is not present
    }
    return email?.slice(atIndex);
  };

  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className="offer-quality-container">
      <div className="offer-logo-section">
        <h4>Offer Quality Report</h4>
      </div>

      <div  className="form-row">
        <div className="form-group">
          <label htmlFor="month">Month</label>
          <select id="month" value={selectedMonth} onChange={handleMonthChange}>
              <option></option>
            {months.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="year">Year</label>
          <select id="year" value={selectedYear} onChange={handleYearChange}>
            {years().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading && <h1>Loading...</h1>}
      {error && 
        <div className="error-message">
          <p>Error: Unable to load the document. Please try again later or contact support@apeirongate.com</p>
        </div>
      }
      {!loading && !error && offerQualityItems &&
        <div>
          <div className="offer-quality-wrapper">
            <table className="offer-quality-table">
              <thead>
                <tr>
                  <th>Carrier</th>
                  <th>Applicant</th>
                  <th>Policy ID</th>
                  <th>Submit Date</th>
                  <th>Offer Date</th>
                  <th>Target Premium</th>
                  <th>Face Amount</th>
                  <th>Tentative Offer</th>
                  <th>Placed</th>
                </tr>
              </thead>
              <tbody>
                {offerQualityItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.carrier || ''}</td>
                    <td>{item.applicant}</td>
                    <td>{item.policyId || ''}</td>
                    <td>{item.submitDate}</td>
                    <td>{item.offerDate || ''}</td>
                    <td>{item.targetPremium || ''}</td>
                    <td>{item.faceAmount || ''}</td>
                    <td>{item.tentativeOffer || ''}</td>
                    <td>{item.placed || ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>      
      }
    </div>
  );
};

export default OfferQualityReport;

