import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Case, Inquiry } from '../../models/Case/case';

interface PriorApplicationInquirySectionProps {
  register: UseFormRegister<Case & Inquiry>;
  errors: FieldErrors<Case & Inquiry>;
  priorInquiryValue: boolean;
  handlePriorInquiryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fields: any[];
  append: (value: Partial<{ carrier: string; offer: string; placed: string }>) => void;
  remove: (index: number) => void;
}

const PriorApplicationInquirySection: React.FC<PriorApplicationInquirySectionProps> = ({
  register,
  errors,
  priorInquiryValue,
  handlePriorInquiryChange,
  fields,
  append,
  remove
}) => (
  <div className="form-section">
    <h3>Prior Application/Inquiry</h3>
    <div className="form-group-100 checkbox-group">
      <label htmlFor="priorInquiry">
        Any personal knowledge that an application or informal inquiry has been sent to or seen by any carrier in the past 12 months?
      </label>
      <div className="radio-group">
        <label>
          <input
            type="radio"
            value="true"
            checked={priorInquiryValue === true}
            onChange={handlePriorInquiryChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            value="false"
            checked={priorInquiryValue === false}
            onChange={handlePriorInquiryChange}
          />
          No
        </label>
      </div>
    </div>

    {priorInquiryValue && (
      <div>
        <div className="form-row">
          <div className="form-group" style={{ width: '200px' }}>
            <label>Carrier</label>
          </div>
          <div className="form-group" style={{ width: '200px' }}>
            <label>Offer</label>
          </div>
          <div className="form-group" style={{ width: '125px' }}>
            <label>Placed</label>
          </div>
          <div className="form-group" style={{ width: '100px' }}>
            <label></label>
          </div>
        </div>
        {fields.map((field, index) => (
          <div className="form-row" key={field.id}>
            <div className="form-group" style={{ width: '200px' }}>
              <input
                id={`priorInquiries.${index}.carrier`}
                {...register(`priorInquiries.${index}.carrier`)}
              />
            </div>
            <div className="form-group" style={{ width: '200px' }}>
              <input
                id={`priorInquiries.${index}.offer`}
                {...register(`priorInquiries.${index}.offer`)}
              />
            </div>
            <div className="form-group" style={{ width: '125px' }}>
              <div className="radio-group">
                <label>
                  <input type="radio" value="Yes" {...register(`priorInquiries.${index}.placed`)} />
                  Yes
                </label>
                <label>
                  <input type="radio" value="No" {...register(`priorInquiries.${index}.placed`)} />
                  No
                </label>
              </div>
            </div>
            <div className="form-group" style={{ width: '100px' }}>
              <button type="button" className='remove-button' onClick={() => remove(index)} style={{ width: '100px' }}>Remove</button>
            </div>
          </div>
        ))}
        <button type="button" className='add-button' onClick={() => append({ carrier: "", offer: "", placed: "" })}>
          Add
        </button>
      </div>
    )}
  </div>
);

export default PriorApplicationInquirySection;
