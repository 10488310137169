import { Link } from 'react-router-dom';

import "./header.css";

import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth, setUserData, setUserToken } from '../../redux/slices/authSlice';
import Logo from '../../assets/apeirongate2.png'

const Header = () => {  
  const dispatch = useAppDispatch();

  const { userData } = useAppSelector(selectAuth);

  const onLogoutHandler = async () => {
    dispatch(setUserToken(null));
    dispatch(setUserData(null));
  };

  return (
    <nav className="navbar navbar-expand">
      <Link to={"/"} className="navbar-logo">
        <img src={Logo} alt="" />
      </Link>

      <div className="user-nav">
        {userData ? (
          <div className="navbar-nav justify-content-center">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                Welcome, {userData.firstName}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={onLogoutHandler}>
                Logout
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav  justify-content-center">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Sign In
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Create Account
              </Link>
            </li> */}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;

