import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc } from 'firebase/firestore';
import { RootState } from '../store';
import { User, isUser } from '../../models/User';
import { db } from '../../firebaseConfig';
import { convertFirestoreTimestampToISO } from '../../utils/convertFirestoreTimestampToDate';

interface AuthState {
  userData: User | null;
  userToken: string | null,
  error: string | null;
  loading: boolean;
}

const initialState: AuthState = {
  userData: JSON.parse(localStorage.getItem('userData') || 'null'),
  userToken: localStorage.getItem('userToken'),
  error: null,
  loading: false,
};

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (uid: string, thunkAPI) => {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const data = userDoc.data();
      if (isUser(data)) {
        const convertedData = convertFirestoreTimestampToISO(data);
        return convertedData;
      } else {
        return thunkAPI.rejectWithValue('Invalid user data structure');
      }
    } else {
      return thunkAPI.rejectWithValue('No such document!');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserToken(state, action: PayloadAction<string | null>) {
      state.userToken = action.payload;
      if (action.payload) {
        localStorage.setItem('userToken', action.payload);
      } else {
        localStorage.removeItem('userToken');
      }
    },
    setUserData(state, action: PayloadAction<User | null>) {
      state.userData = action.payload;
      if (action.payload) {
        localStorage.setItem('userData', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('userData');
      }
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.userData = action.payload;
      localStorage.setItem('userData', JSON.stringify(action.payload));
      state.loading = false;
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });
    builder.addCase(fetchUserData.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { setUserToken, setUserData, setError, setLoading } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
