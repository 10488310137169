import { Carrier } from "../models/carrier";

export const carrierContacts: Carrier[] = [
  {
    exchangePartner: 'AIN',
    code: 'symetra',
    name: 'Symetra',
    email: 'PremierNewBusiness@Symetra.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'securian',
    name: 'Securian Financial',
    email: 'LifeQuickQuote@securian.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'prudential',
    name: 'Prudential',
    email: 'pruain@prudential.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'protective',
    name: 'Protective Life',
    email: 'informals@protective.com',
    active: true,
    excludeNY: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'principal',
    name: 'Principal Financial',
    email: 'PrincipalBusiness@exchange.principal.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'newyorklike',
    name: 'New York Life',
    email: 'AMN_NB@NewYorkLife.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'nationwide',
    name: 'Nationwide',
    email: 'ISRAEK1@nationwide.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'mutualomaha',
    name: 'Mutual of Omaha',
    email: 'AINtrials@mutualofomaha.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'massmutual',
    name: 'MassMutual',
    email: 'MMSDNewBusiness@MassMutual.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'lincoln',
    name: 'Lincoln Financial',
    email: 'AINNB@LFG.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'legalgeneral',
    name: 'Legal & General',
    email: 'www.lgaquickquote.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'johnhancock',
    name: 'John Hancock',
    email: '',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'equitable',
    name: 'Equitable',
    email: 'Equitableinformal@equitable.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'corebridge',
    name: 'Corebridge',
    email: 'informals@corebridgefinancial.com',
    active: true,
  },
  {
    exchangePartner: 'AIN',
    code: 'anico',
    name: 'ANICO',
    email: '',
    active: true,
  },
  {
    exchangePartner: 'LS',
    code: 'anico2',
    name: 'ANICO2',
    email: '',
    active: true,
  },
];


// export const carrierContacts: Carrier[] = [
//   { exchangePartner: 'AIN', mask: 'Carrier A', code: 'symetra', name: 'Symetra', email: 'PremierNewBusiness@Symetra.com', active: true },
//   { exchangePartner: 'AIN', mask: 'Carrier B', code: 'securian', name: 'Securian Financial', email: 'LifeQuickQuote@securian.com', active: true },
//   { exchangePartner: 'AIN', mask: 'Carrier C', code: 'prudential', name: 'Prudential', email: 'pruain@prudential.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier D', code: 'protective', name: 'Protective Life', email: 'informals@protective.com', active: true, excludeNY: true },
//   { exchangePartner: 'AIN', mask: 'Carrier E', code: 'principal', name: 'Principal Financial', email: 'PrincipalBusiness@exchange.principal.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier F', code: 'newyorklike', name: 'New York Life', email: 'AMN_NB@NewYorkLife.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier G', code: 'nationwide', name: 'Nationwide', email: 'ISRAEK1@nationwide.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier H', code: 'mutualomaha', name: 'Mutual of Omaha', email: 'AINtrials@mutualofomaha.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier I', code: 'massmutual', name: 'MassMutual', email: 'MMSDNewBusiness@MassMutual.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier J', code: 'lincoln', name: 'Lincoln Financial', email: 'AINNB@LFG.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier K', code: 'legalgeneral', name: 'Legal & General', email: 'www.lgaquickquote.com', active: true  },
//   { exchangePartner: 'AIN', mask: 'Carrier L', code: 'johnhancock', name: 'John Hancock', email: '', active: true },
//   { exchangePartner: 'AIN', mask: 'Carrier M', code: 'equitable', name: 'Equitable', email: 'Equitableinformal@equitable.com', active: true },
//   { exchangePartner: 'AIN', mask: 'Carrier N', code: 'corebridge', name: 'Corebridge', email: 'informals@corebridgefinancial.com', active: true },
//   { exchangePartner: 'AIN', mask: 'Carrier ', code: 'anico', name: 'ANICO', email: '', active: true },
//   { exchangePartner: 'LS', mask: 'Carrier ', code: 'anico2', name: 'ANICO2', email: '', active: true },
// ]