import { Viewer, Worker, ScrollMode  } from '@react-pdf-viewer/core';
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { pageNavigationPlugin  } from '@react-pdf-viewer/page-navigation';

import "./documentviewer.css";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import { useEffect, useState } from 'react';
import { fetchSignedUrl } from '../../services/summary.service';
import { useParams } from 'react-router-dom';


const DocumentViewer = (props: any) => {
  const { uid } = useParams<{ uid: string }>();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const [fileUrl, setFileUrl] = useState<string>(); 
  const [isFileFound, setIsFileFound] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    async function loadPdf() {
      try {
        const signedUrl = await fetchSignedUrl(`${uid}.pdf`);
        setFileUrl(signedUrl);
        setIsFileFound(true);
      } catch (error) {
        setErrorMessage('Unable to load the document. Please try again later or contact support@apeirongate.com');
        setIsFileFound(false);
      }
    }
    
    loadPdf();
  }, [uid]);

 
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    NumberOfPages: () => <></>
  });

  const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  
  const defaultLayoutPluginInstance = defaultLayoutPlugin({renderToolbar });
  const zoomPluginInstance = zoomPlugin();
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <>
      {isFileFound ? (
        <div className="document-container">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <Viewer
              fileUrl={`${fileUrl}`}
              plugins={[zoomPluginInstance, defaultLayoutPluginInstance, pageNavigationPluginInstance]}
              scrollMode={ScrollMode.Vertical}
            />
          </Worker>
        </div>
      ) : (
        errorMessage && (
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        )
      )}
    </>
  )
}

export default DocumentViewer;