import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyDNB2SdcF3CtTEOPrt7VMU7jHj5HJ-2NZs",
  authDomain: "apeirongateportal.firebaseapp.com",
  projectId: "apeirongateportal",
  storageBucket: "apeirongateportal.appspot.com",
  messagingSenderId: "740929883588",
  appId: "1:740929883588:web:7d59122961d356a617e891",
  measurementId: "G-3L1613DNM2"
};

// export const firebaseConfig = {
//   apiKey: process.env.APP_FIREBASE_API_KEY,
//   authDomain: process.env.APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.APP_FIREBASE_APP_ID,
//   measurementId: process.env.APP_FIREBASE_MEASUREMENT_ID,
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };