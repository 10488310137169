import { SetStateAction, useEffect, useState } from 'react';
import { useForm, } from 'react-hook-form';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

import "./offerdetail.css";
import { ExchangeRelay } from '../../models/exchangeRelay';
import toast from 'react-hot-toast';

interface OfferDetailProps {
  exchangeRelay: ExchangeRelay; 
  firstName: string; 
  lastName: string;
  setModalOpen: (event: SetStateAction<boolean>) => void;
}

const OfferDetail: React.FC<OfferDetailProps> = ({ exchangeRelay, firstName, lastName, setModalOpen }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit, setValue } = useForm<ExchangeRelay>();

  useEffect(() => {
    const fetchExchangeRelay = async () => {
      try {
        setLoading(true);
        setError(null);

        // Populate form fields with case data
        Object.keys(exchangeRelay).forEach(key => {
          setValue(key as keyof ExchangeRelay, exchangeRelay[key as keyof ExchangeRelay]);
        });        
      } catch (err: any) {
        setError(err.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeRelay();
  }, [setValue, exchangeRelay]);

  const onSubmit = async (data: ExchangeRelay) => {
    if (!data.id) {
      setError("Invalid data");
      return;
    }

    try {
      const docRef = doc(db, "exchangeRelay", data.id);
      await updateDoc(docRef, data);
      toast.success('Record saved successfully!');
      setModalOpen(false);
    } catch (err: any) {
      setError(err.message || "Error saving data");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className='case-container'>
      {!loading &&
        <>
          <div className="form-section">
            <h3>
            { exchangeRelay?.carrierCode?.toLocaleUpperCase() } - { firstName } { lastName }
            </h3>
          </div>
          
          <form onSubmit={handleSubmit(onSubmit)} className="exchange-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="carrierId">ID #</label>
                <input id="carrierId" {...register('carrierId')} />
              </div>

              <div className="form-group">
                <label htmlFor="offerAmount">Offer Amount</label>
                <input id="offerAmount" {...register('offerAmount')} />
              </div>

              <div className="form-group">
                <label htmlFor="offerDate">Offer Date</label>
                <input id="offerDate" type="date" {...register('offerDate')} />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group-wide">
                <label htmlFor="tentativeOffer">Tentative Offer</label>
                <textarea id="tentativeOffer" {...register('tentativeOffer')} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group-wide">
                <label htmlFor="offerDetails">Offer Details</label>
                <textarea id="offerDetails" {...register('offerDetails')} />
              </div>
            </div>

            <div className="form-row placed-row">
              <input type="checkbox" {...register('placed')} /> Placed?
            </div>

            <button type="submit">Submit</button>
          </form>
        </>
      }
    </div>
  );
};

export default OfferDetail;

