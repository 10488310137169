import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { object, string, TypeOf } from 'zod';
import { LoadingButton } from '@mui/lab';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { Box, Container, Typography } from '@mui/material';
import FormInput from "../../../components/FormInputs/FormInput";
import { LinkItem } from "../../../components/LinkItem";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { fetchUserData, selectAuth, setError, setLoading, setUserData, setUserToken } from "../../../redux/slices/authSlice";

import './login.css';

const loginSchema = object({
  email: string()
    .min(1,'Email address is required')
    .email('Email Address is invalid'),
  password: string()
    .min(1,'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const Login = () => {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const navigate = useNavigate();
  //const location = useLocation();

  //const from = ((location.state as any)?.from.pathname as string) || '/';
  const { handleSubmit } = methods;

  const dispatch = useAppDispatch();
  const {  loading } = useAppSelector(selectAuth);

  const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {
    dispatch(setUserData(null));
    dispatch(setLoading(true));
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
      const user = userCredential.user;

      const accessToken = await user.getIdToken();
      dispatch(setUserToken(accessToken));
      dispatch(fetchUserData(user.uid));
      toast.success('Login successful!');
      navigate('/');
    } catch (error) {
      if (error instanceof Error) {
        console.log("An error occurred: ", error.message);
        dispatch(setError(error.message));
      } else {
        console.log("An unexpected error occurred");
        dispatch(setError('An unexpected error occurred'));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Container maxWidth={false} className="login-container" sx={{
      display: 'flex',
    }}>
      <Box className="login-box"> 
        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            className="login-form-box"
          >
            <div className="typography-login-h2">
              Sign in 
            </div>

            <Typography className="typography-login-links">
              Need an account? <LinkItem to='/register'>Create an account</LinkItem>
            </Typography>

            <FormInput name='email' label='Email' type='email' />
            <FormInput name='password' label='Password' type='password' />
            
            <Typography className="typography-login-links">
              <LinkItem to='/forgotpassword'>Forgot Password?</LinkItem>
            </Typography>

            <LoadingButton
              className="loading-button"
              variant='contained'
              fullWidth
              disableElevation
              type='submit'
              loading={loading}
            >
              Sign in
            </LoadingButton>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default Login;