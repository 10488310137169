import  { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Case } from '../../models/Case/case';
import { adminFetchCasesBatch } from '../../services/case-data.service';

import "./admincasemanager.css";
import { Button } from 'react-bootstrap';

const AdminCaseManager = () => {
  const navigate = useNavigate();
  
  const [cases, setCases] = useState<Case[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterText, setFilterText] = useState('');
	const [resetPagination, setResetPagination] = useState(false)

  useEffect(() => {
    const fetchCases = async () => {
      try {
        setLoading(true);
        const batchSize = 500;
        const querySnapshot = await adminFetchCasesBatch(batchSize);
  
        // Map the fetched cases
        const liveCases = querySnapshot.docs.map(
          (doc) => ({ uid: doc.id, ...doc.data() } as Case)
        );
  
        // Update state with the fetched data
        setCases(liveCases);
      } catch (error) {
        console.error("Error fetching cases:", error);
      } finally {
        setLoading(false);
      }
    };
  
    // Call the fetch function when the component mounts
    fetchCases();
  }, []); // Empty dependency array ensures it only runs on mount
  

  const handleViewCase = (uid: string) => navigate(`/case/${uid}`);
  const handleSendCase = (uid: string, appId: string) => navigate(`/adminsendqueue/${uid}`);
  const handleViewOffer = (uid: string) => navigate(`/case/${uid}/offerDetail`);

  const filteredCases = cases.filter(caseItem =>
    caseItem.lastName?.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns: any[] = [
    {  
      name: 'First Name',  
      selector: (row: { firstName: any; }) => row.firstName,
      sortable: true,
    },
    {  
      name: 'Last Name',  
      selector: (row: { lastName: any; }) => row.lastName,
      sortable: true,
    },
    {  
      name: 'DOB',  
      selector: (row: { dob: any; }) => {
        if (row.dob) {
          const [year, month, day] = row.dob.split('-');
          return `${month}/${day}/${year}`;
        }
        return '';
      },
    },
    {  
      name: 'Summary Status',
      cell: (row: { summaryStatus: string; appId: string }) => (
        row.summaryStatus === 'Completed' ? (
          <a href={`/case/${row.appId}/summary`}>
            {row.summaryStatus}
          </a>
        ) : (
          row.summaryStatus
        )
      ),
    },
    {
      name: 'Informal Inquiry',
      cell: (row: { uid: string; inquiryStatus: string }) => (
        <div className="left-align">
        <button
          onClick={() => handleViewCase(row.uid)}
          className={`inquiry-button ${row.inquiryStatus !== 'Completed' ? 'incomplete-status' : ''}`}
        >
          {row.inquiryStatus !== 'Completed' ? 'Start' : 'Revisit'}
        </button>
      </div>
      ),
    },
    {
      name: 'Exchange',
      cell: (row: { uid: string, appId: string, relayIds: string[], offeredRelayIds: string[] }) => (
        <div className="left-align">
          <button onClick={() => handleSendCase(row.uid, row.appId)} 
            className={`inquiry-button ${row.relayIds?.length === row.offeredRelayIds?.length ? '' : 'incomplete-status'}`}
          >
            Shop ({row.relayIds?.length || 0})
          </button>
        </div>
      ),
    },
    {
      name: 'Offers',
      cell: (row: { uid: string, offeredRelayIds: string[] }) => (
        <div className="left-align">
          { row.offeredRelayIds?.length > 0 &&
            <button onClick={() => handleViewOffer(row.uid)} className={`inquiry-button`}>
              View ({row.offeredRelayIds?.length || 0})
            </button>
          }
        </div>
      ),
    }
  ];

  const subHeaderComponentMemo = useMemo(() => {
		const clearFilter = () => {
			if (filterText) {
				setResetPagination(!resetPagination);
				setFilterText('');
			}
		};

		return (
			<>
				<input
					id="search"
					type="text"
					placeholder="Filter By Last Name"
					aria-label="Search Input"
					value={filterText}
					onChange={e => setFilterText(e.target.value)}
					className="search-input"
				/>
				<Button type="button" onClick={clearFilter} className="reset-button">X</Button>
			</>
		);
	}, [filterText, resetPagination]);

  return (
    <div className='casemanager-container'>      
      {loading && <h1>Loading...</h1>}
      {!loading && 
        <div className='data-table'>
          <div className="form-section">
            <h2>Admin Case Manager</h2>
          </div>
          <DataTable
            columns={columns}
            data={filteredCases}
            pagination
            highlightOnHover
            paginationPerPage={10}
            subHeader
			      subHeaderComponent={subHeaderComponentMemo}
          />
        </div>
      }
      
    </div>
  );
};

export default AdminCaseManager;
