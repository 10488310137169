import React from 'react';

const FileList: React.FC<{ files: File[]; onDelete: (index: number) => void }> = ({ files, onDelete }) => (
  <div className="file-list">
    <h3>Selected Files</h3>
    {files.length === 0 ? (
      <p>No files selected</p>
    ) : (
      <ul>
        {files.map((file, index) => (
          <li key={index} className="file-item">
            <span className="file-name">{file.name}</span>
            <button type="button" className="delete-button" onClick={() => onDelete(index)}>
              <i className="fas fa-trash-alt"></i>
            </button>
          </li>
        ))}
      </ul>
    )}
  </div>
);

export default FileList;
