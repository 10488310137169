import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Case } from '../../models/Case/case';

interface AgencySectionProps {
  register: UseFormRegister<Case>;
  errors: FieldErrors<Case>;
}

const AgencySection: React.FC<AgencySectionProps> = ({ register, errors }) => (
  <div className="form-section">
    <h3>Agency</h3>
    <div className="form-row">
      <div className="form-group">
        <label htmlFor="agency">Agency Name</label>
        <input id="agency" {...register('agency')} />
        {errors.agency && <span style={{ color: 'red' }}>{errors.agency.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="agentfirstName">Contact First Name</label>
        <input id="agentfirstName" {...register('agentfirstName')} />
        {errors.agentfirstName && <span style={{ color: 'red' }}>{errors.agentfirstName.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="agentlastName">Contact Last Name</label>
        <input id="agentlastName" {...register('agentlastName')} />
        {errors.agentlastName && <span style={{ color: 'red' }}>{errors.agentlastName.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="agentEmail">Contact Email</label>
        <input id="agentEmail" type="email" {...register('agentEmail')} />
        {errors.agentEmail && <span style={{ color: 'red' }}>{errors.agentEmail.message}</span>}
      </div>
    </div>
  </div>
);

export default AgencySection;
