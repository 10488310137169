import { Box, Container } from '@mui/material';
import AINExchangeImage from '../../assets/landing/AINExchange_Logo.png'
import LSExchangeImage from '../../assets/landing/LSExchange_Logo.png'
import ExchangeImage from '../../assets/landing/Exchange_Logo.png'
import Image2 from '../../assets/landing/Web_Landing__White_Text_TealIcons.png'
import Image3 from '../../assets/landing/Powered_By_AG_Logo.png'
import "./home.css";
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { useEffect, useState } from 'react';

const Home = () => {
  const { userData } = useAppSelector(selectAuth);
  const [exchangePartner, setExchangePartner] = useState<string>(""); 

  useEffect(() => {
    const exchangePartnerFilter = userData?.exchangePartner ?? "";
    setExchangePartner(exchangePartnerFilter);
  }, [userData]);
  
  return (
    <Container maxWidth={false} className="home-container">
      <Box className="logo-box" sx={{ mb: 2 }}> {/* Add some margin to the bottom to create space between boxes */}
        {exchangePartner === 'AIN' && <img className="image image-logo" src={AINExchangeImage} alt="Apeiron Gate" /> }
        {exchangePartner === 'LS' && <img className="image image-logo" src={LSExchangeImage} alt="Apeiron Gate" />}
        {exchangePartner === '' && <img className="image image-logo" src={ExchangeImage} alt="Apeiron Gate" /> }
      </Box>
      <Box className="home-box">
        <img className="image" src={Image2} alt="Apeiron Gate" />
        <img className="image3" src={Image3} alt="Apeiron Gate" />
      </Box>
    
    </Container>
  );
};

export default Home;
