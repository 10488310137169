import { Timestamp } from 'firebase/firestore';

export const convertFirestoreTimestampToISO = (data: any) => {
  if (data.createdAt && data.createdAt instanceof Timestamp) {
    return {
      ...data,
      createdAt: data.createdAt.toDate().toISOString(),
    };
  }
  return data;
};
