import React from "react";
import { carrierContacts } from "../../types/carrierContacts";
import { months, years } from "../../utils/dateTime";

type FilterProps = {
  onFilterChange: (carrier: string, month: number, year: number) => void;
  defaultCarrier?: string;
  defaultMonth?: number;
  defaultYear?: number;
};

const CarrierFilter: React.FC<FilterProps> = ({
  onFilterChange,
  defaultCarrier = "",
  defaultMonth = new Date().getMonth(),
  defaultYear = new Date().getFullYear(),
}) => {
  const [selectedCarrier, setSelectedCarrier] = React.useState(defaultCarrier);
  const [selectedMonth, setSelectedMonth] = React.useState(defaultMonth);
  const [selectedYear, setSelectedYear] = React.useState(defaultYear);

  const handleCarrierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedCarrier(value);
    onFilterChange(value, selectedMonth, selectedYear);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    setSelectedMonth(value);
    onFilterChange(selectedCarrier, value, selectedYear);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    setSelectedYear(value);
    onFilterChange(selectedCarrier, selectedMonth, value);
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="carrier">Carrier</label>
        <select id="carrier" value={selectedCarrier} onChange={handleCarrierChange}>
          <option value="">Select Carrier</option>
          {carrierContacts.sort((a, b) => a.name.localeCompare(b.name)).map((carrier) => (
            <option key={carrier.code} value={carrier.code}>
              {carrier.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="month">Month</label>
        <select id="month" value={selectedMonth} onChange={handleMonthChange}>
          {months.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="year">Year</label>
        <select id="year" value={selectedYear} onChange={handleYearChange}>
          {years().map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default CarrierFilter;
