import "./orderrecords.css";

import OrderLogo from '../../assets/orderrecords/Record_Retrieval_Header.png'
import OrderButton from '../../assets/orderrecords/Order_Your_Records_Header.png'
const OrderRecords = () => {

  return (
    <div className="order-records-container">
      <div className="order-records-title-section">
        <img className="order-records-image" src={OrderLogo} alt="Apeiron Gate" />
        {/* <h2>Shopping List</h2> */}
      </div>
      <p className="order-records-description">
        The AIN Exchange is an end-to-end solution that simplifies the Trial Market by uniting sales, operations, and vendor support. We are proud to work 
        alongside Jetstream APS as our primary medical record retrieval partner. If you utilize Jetstream APS for your record retrieval needs, 
        participation in The AIN Exchange is just one click away! 
      </p>
      <p className="order-records-description">
        When it's time to order records for your informal applications, simply select the "AIN Exchange" button on the Jetstream APS order form. 
        After your records are ordered, you'll receive an e-mail with log-in information to The AIN Exchange portal with additional details on 
        how to shop your case through the Exchange. You're going to love The AIN Exchange process.  
      </p>
      <p className="order-records-description">
        <a href="https://www.jetstreamaps.com" className="order-records-link" target="_blank"  rel="noopener noreferrer">
          <img src={OrderButton} alt="Apeiron Gate" />
        </a>
      </p>
      <p className="order-records-title-section">
        ABOUT JETSTREAM APS
      </p>
      <p className="order-records-jetstream">
       Jetstream APS is a leading medical record retrieval company serving the life insurance industry. With more than 30 years of experience, 
        Jetstream APS understands how important it is for clients to receive their Attending Physician Statements in a timely manner. They are 
        committed to providing the fastest, most efficient service possible and take a highly proactive approach to all medical facility interactions. 
        We are proud to partner with Jetstream APS through The AIN Exchange. 
      </p>
    </div>
  );
};

export default OrderRecords;
