import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Carrier } from '../../models/carrier';
import { addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, query, Timestamp, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { ExchangeRelay } from '../../models/exchangeRelay';
import { Case } from '../../models/Case/case';

import AinExchangeLogo from '../../assets/ainexchange/AINExchange_Logo_Shoppinglist5_Teal.png'
import LsExchangeLogo from '../../assets/ainexchange/LSExchange_Logo_Shoppinglist5_Teal.png'
import "./sendqueue.css";
import { carrierContacts } from '../../types/carrierContacts';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';

const SendQueue = () => {
  const { uid, appId } = useParams<{ uid: string; appId: string }>();
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [exchangeRelays, setExchangeRelays] = useState<ExchangeRelay[]>([]);
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [caseData, setCaseData] = useState<Case | null>(null); 
  const [exchangePartner, setExchangePartner] = useState<string>(""); 

  const { userData } = useAppSelector(selectAuth);
  
  useEffect(() => {
    const fetchCarriers = () => {
      const sortedCarriers = [...carrierContacts].sort((a, b) => a.name.localeCompare(b.name));
      const exchangePartnerFilter = userData?.exchangePartner ?? "AIN";
      setExchangePartner(exchangePartnerFilter);
      setCarriers(sortedCarriers.filter(i => i.exchangePartner === exchangePartnerFilter));
    };

    const fetchCaseData = async () => {
      if (uid) {
        const caseDocRef = doc(db, "cases", uid); // Use the uid to reference the document
        const caseDoc = await getDoc(caseDocRef);
        if (caseDoc.exists()) {
          var c = caseDoc.data();
          const date = new Date(c.dob);

          // Get the month, day, and year from the Date object
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
          const day = date.getDate().toString().padStart(2, '0');
          const year = date.getFullYear();
        
          c.dob = `${month}/${day}/${year}`;
          // Format the date as mm/dd/yyyy
          setCaseData(c); // Store the case data in state
        } else {
          console.log("No such document in cases!");
        }
      }
    };

    const subscribeToExchangeRelays = () => {
      const relayCollection = collection(db, "exchangeRelay");
      const relayQuery = query(relayCollection, where("caseUid", "==", uid));

      const unsubscribe = onSnapshot(relayQuery, (relaySnapshot) => {
        const relayList = relaySnapshot.docs.map((doc) => ({
          id: doc.id, // Store the document ID
          ...doc.data(),
        }) as ExchangeRelay);
        setExchangeRelays(relayList);

        // Extract carrierCodes from the relays and set the selectedCarriers state
        const carrierCodes = relayList.map((relay) => relay.carrierCode || "");
        setSelectedCarriers(carrierCodes);
      });

      return unsubscribe;
    };

    fetchCarriers();
    fetchCaseData(); 
    const unsubscribe = subscribeToExchangeRelays();

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [uid, userData]);

  const handleCheckboxChange = async (carrier: Carrier) => {
    const carrierCode = carrier.code || "";
    const carrierEmail = carrier.email || "";
    const isChecked = selectedCarriers.includes(carrierCode);
    const matchingRelay = exchangeRelays.find((relay) => relay.carrierCode === carrierCode);

    if (isChecked) {
      setSelectedCarriers((prevSelected) =>  prevSelected.filter((code) => code !== carrierCode)
      );

      if (matchingRelay && matchingRelay.id) {
        try {
          await deleteDoc(doc(db, "exchangeRelay", matchingRelay.id));
          console.log("ExchangeRelay record deleted successfully:", matchingRelay);
        } catch (error) {
          console.error("Error deleting ExchangeRelay record:", error);
        }
      }
    } else {
      setSelectedCarriers((prevSelected) => [...prevSelected, carrierCode]);

      const newExchangeRelay: ExchangeRelay = {
        caseUid: uid,
        appId: appId,
        agentEmail: userData?.email,
        carrierCode: carrierCode,
        carrierEmail: carrierEmail,
        status: "Scheduled to Send",
        createdDate: Timestamp.now(), 
      };

      try {
        await addDoc(collection(db, "exchangeRelay"), newExchangeRelay);
      } catch (error) {
        console.error("Error adding ExchangeRelay record:", error);
      }
    }
  };

  return (
    <div className="container">
      <div className='data-table'>
        <div className="exchange-title-section">
          {exchangePartner === 'AIN' && <img className="exchange-image" src={AinExchangeLogo} alt="Apeiron Gate" /> }   
          {exchangePartner === 'LS' && <img className="exchange-image" src={LsExchangeLogo} alt="Apeiron Gate" /> }
        </div>
        {caseData && <p className='send-queue-subheader'><span  className='subheader-bold'>{caseData.firstName} {caseData.lastName} </span>{caseData.dob}</p> }
        <table className="send-queue-table">
          <thead>
            <tr>
              <th>Send Report</th>
              <th>Carrier Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {carriers.map((carrier) => {
              const matchingRelay = exchangeRelays.find(
                (relay) => relay.carrierCode === carrier.code
              );
              const isChecked = matchingRelay !== undefined;
              const isDisabled = matchingRelay?.status === "Sent" || !carrier.email || !carrier.active || (carrier.excludeNY && caseData?.isNewYork);

              return (
                <tr key={carrier.code} className="table-row">
                  <td className="centered">
                    <input
                      type="checkbox"
                      checked={isChecked || selectedCarriers.includes(carrier.code || "")}
                      onChange={() => handleCheckboxChange(carrier)}
                      className="checkbox"
                      disabled={isDisabled}
                    />
                  </td>
                  <td className='carrier-column'>{carrier.name}</td>
                  <td className='status-column'>{matchingRelay?.status} { matchingRelay?.sendDate || "" }</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SendQueue;
