import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UnauthorizePage = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="body1" gutterBottom>
          We're sorry, but you do not have the necessary permissions to view this page.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you believe this is an error, please contact your administrator or try logging in with a different account.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/home')}
          sx={{ mt: 2 }}
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default UnauthorizePage;