import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectAuth } from "../../redux/slices/authSlice";

export const RequireAuth = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { userData } = useAppSelector(selectAuth);

  const location = useLocation();

  let authorized = true;
  if (allowedRoles.length > 0) {
    authorized = allowedRoles.some(allowedRole => userData?.roles?.includes(allowedRole));
  }

  return userData && authorized ? (
    <Outlet />
  ) : userData && !authorized ? (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
}