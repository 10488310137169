import { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const Reset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setMessage("");
    setError("");

    if (!email) {
        setError("Please enter your email.");
        return;
    }

    const auth = getAuth();

    try {
        await sendPasswordResetEmail(auth, email);
        setMessage("Password reset email sent. Please check your inbox.");
    } catch (err) {
        setError("Failed to send password reset email. Please try again.");
        console.error(err);
    }
};

  return (
    <div className='container'>
      <h3>Forgot Password</h3>
      <form onSubmit={handleSubmit}>
      <div className="form-row"></div>
          <div className="form-group">
            <label htmlFor="firstName">Enter your email</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <button type="submit">Send Reset Email</button>
      </form>
      {message && <p style={{ color: "green", marginTop: "1rem" }}>{message}</p>}
      {error && <p style={{ color: "red", marginTop: "1rem" }}>{error}</p>}
    </div>
  );
};

export default Reset;