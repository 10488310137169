import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectAuth, setUserData, setUserToken } from '../redux/slices/authSlice';
import { useAppSelector } from '../hooks/redux-hooks';

export const AuthManager = () => {
  const dispatch = useDispatch();
  const { userToken } = useAppSelector(selectAuth);

  useEffect(() => {
    if (userToken) {
      const nineHoursInMs = 9 * 60 * 60 * 1000;

      const timer = setTimeout(() => {
        console.log("dispatching timeout")
        dispatch(setUserToken(null));
        dispatch(setUserData(null));
      }, nineHoursInMs);

      return () => clearTimeout(timer);
    }
  }, [userToken, dispatch]);

  return null; 
};
