import { useEffect, useState } from 'react';
import { ExchangeRelay } from '../../models/exchangeRelay';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { fetchInquiriesByCaseUid } from '../../services/inquiry-data.service';
import "./invoicebackupreport.css";
import { getCaseByAppId } from '../../services/case-data.service';
import { InvoiceBackupItem } from '../../models/invoiceitem';
import InvoiceTable from './invoiceTable';
import { exportToPDF } from './invoicebackuppdf';
import { exportToExcel } from './invoicebackupxls';
import XlsDownload from '../../assets/xlsdownload.png'
import PdfDownload from '../../assets/pdfdownload.png'
import { getDefaultMonthYear } from '../../utils/dateTime';
import CarrierFilter from '../CarrierFilter/carrierfilter';

const InvoiceBackupReport = () => {
  const { defaultMonth, defaultYear } = getDefaultMonthYear();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceBackupItem[]>([]);

  const handleFilterChange = (carrier: string, month: number, year: number) => {
    setSelectedCarrier(carrier);
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const handlePdfExportClick = () => {
    const fileName = `${selectedCarrier}_${selectedMonth+1}_${selectedYear}`;
    exportToPDF(invoiceItems, fileName);
  };

  const handleExcelExport = () => {
    const fileName = `${selectedCarrier}_${selectedMonth+1}_${selectedYear}`;
    exportToExcel(invoiceItems, fileName);
  };

  const formatDate = (dateTime: string | undefined): string => {
    if (!dateTime) return "";
    return dateTime.split(' ')[0]; // Extract the date portion
  };

  useEffect(() => {
    const fetchRecords = async () => {
      if (!selectedCarrier || selectedMonth === undefined || selectedYear === undefined) {
        return;
      }
  
      try {
        const month = selectedMonth + 1; // Adjust for 0-based index
        const year = selectedYear;
  
        const exchangeRelayRef = collection(db, "exchangeRelay");
        const q = query(
          exchangeRelayRef,
          where("carrierCode", "==", selectedCarrier),
          where("month", "==", month),
          where("year", "==", year)
        );
  
        const querySnapshot = await getDocs(q);
  
        const invoiceItems = await Promise.all(
          querySnapshot.docs.map(async (doc) => createInvoiceItem(doc.data()))
        );
  
        setInvoiceItems(invoiceItems);
      } catch (error) {
        console.error("Error fetching records:", error);
      }
    };

    const createInvoiceItem = async (exchangeRelay: ExchangeRelay): Promise<InvoiceBackupItem> => {
      const invoiceItem: InvoiceBackupItem = {
        submitDate: formatDate(exchangeRelay.sendDate),
        policyId: exchangeRelay.carrierId,
      };
    
      try {
        // Fetch caseData if appId exists
        if (exchangeRelay.appId) {
          const caseData = await getCaseByAppId(exchangeRelay.appId);
          if (caseData) {
            invoiceItem.agency = caseData.agency;
            invoiceItem.lastName = caseData.lastName;
          }
        }
    
        // Fetch inquiries if caseUid exists
        if (exchangeRelay.caseUid) {
          const informalInquiries = await fetchInquiriesByCaseUid(exchangeRelay.caseUid);
          if (informalInquiries.length > 0) {
            const informalInquiry = informalInquiries[0];
            invoiceItem.targetPremium = informalInquiry.targetPremium;
            invoiceItem.faceAmount = informalInquiry.faceAmount;
          }
        }
      } catch (error) {
        console.error(`Error processing exchangeRelay with ID ${exchangeRelay.carrierId || "unknown"}:`, error);
      }
    
      return invoiceItem;
    };
  
    setLoading(true);
    fetchRecords();
    setLoading(false);
  }, [selectedCarrier, selectedMonth, selectedYear]);
  
  const getTotalFace = () => {
    let total = 0;
    invoiceItems.forEach((item) => {
      const faceAmount = Number(item.faceAmount);
      if (!isNaN(faceAmount)) {
        total = total + faceAmount;
      } else {
        return 0;
      }
    })

    return total;
  };

  const getTotalTargetPremium = () => {
    let total = 0;
    invoiceItems.forEach((item) => {
      const targetPremium = Number(item.targetPremium);
      if (!isNaN(targetPremium)) {
        total = total + targetPremium;
      } else {
        return 0;
      }
    })

    return total;
  };
  
  return (
    <div className="container">
      <div className="form-section">
        <h4>Invoice Backup</h4>
      </div>

      <div className="form-row">
        <CarrierFilter
          onFilterChange={handleFilterChange}
          defaultCarrier={selectedCarrier}
          defaultMonth={selectedMonth}
          defaultYear={selectedYear}
        />
        <div className="form-group button-group">
          <div className="button-container">
            <button onClick={handleExcelExport} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <img src={XlsDownload} alt="Apeiron Gate" style={{ height: '32px', width: '32px' }}/>
            </button>
          </div>
        </div>
        <div className="form-group button-group">
          <div className="button-container">
            <button onClick={handlePdfExportClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <img src={PdfDownload} alt="Apeiron Gate" style={{ height: '32px', width: '32px' }} />
            </button>
          </div>
        </div>
      </div>

      {loading && <h4>Loading...</h4>}
      {!loading && invoiceItems.length > 0 &&
        <div className="invoice-table-wrapper">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Total Cost</th>
                <th>Total Trial Packages Received</th>
                <th>Total Face Amount</th>
                <th>Total Associated Target Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{ invoiceItems.length * 30 }</td>
                <td>{ invoiceItems.length }</td>
                <td>{ getTotalFace() }</td>
                <td>{ getTotalTargetPremium() }</td>
              </tr>
            </tbody>
          </table>
          <InvoiceTable invoiceItems={invoiceItems} />
        </div>
      }
    </div>
  );
};

export default InvoiceBackupReport;

