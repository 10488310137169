import React from 'react';

const Spinner: React.FC<{ message: string }> = ({ message }) => (
  <div className="spinner-overlay">
    <div className="spinner"></div>
    <p>{message}</p>
  </div>
);

export default Spinner;
