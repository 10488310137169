import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Case, Inquiry } from '../../models/Case/case';

interface InformalGoalsSectionProps {
  register: UseFormRegister<Case & Inquiry>;
  retentionOnlyValue: boolean | undefined;
  handleRetentionOnlyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  premiumFinanceValue: boolean | undefined;
  handlePremiumFinanceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  survivorshipValue: boolean | undefined;
  handleSurvivorshipChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: FieldErrors<Case & Inquiry>;
}

const InformalGoalsSection: React.FC<InformalGoalsSectionProps> = ({
  register, 
  retentionOnlyValue,
  handleRetentionOnlyChange,
  premiumFinanceValue,
  handlePremiumFinanceChange,
  survivorshipValue,
  handleSurvivorshipChange,
  errors 
}) => (
  <div className="form-section">
    <h3>Informal Goals</h3>
    <div className="form-row">
      <div className="form-group" style={{ width: '200px' }}>
        <label htmlFor="faceAmount">Face Amount</label>
        <input id="faceAmount" {...register('faceAmount')} />
        {errors.faceAmount && <span style={{ color: 'red' }}>{errors.faceAmount.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="targetPremium">Target Premium</label>
        <input id="targetPremium" {...register('targetPremium')} />
        {errors.targetPremium && <span style={{ color: 'red' }}>{errors.targetPremium.message}</span>}
      </div>
      <div className="form-group" style={{ width: '225px' }}>
        <label htmlFor="productType">Product Type</label>
        <select id="productType" {...register('productType')}>
          <option value=""></option>
          <option value="Term">Term</option>
          <option value="Whole Life">Whole Life</option>
          <option value="IUL">IUL</option>
          <option value="UL">UL</option>
          <option value="VUL">VUL</option>
          <option value="Other *Specify in Narrative">Other *Specify in Narrative</option>
          <option value="Subject to Quote">Subject to Quote</option>
        </select>
        {errors.productType && <span style={{ color: 'red' }}>{errors.productType.message}</span>}
      </div>
      <div className="form-group" style={{ width: '200px' }}>
        <label htmlFor="targetRating">Desired Rating</label>
        <select id="targetRating" {...register('targetRating')}>
          <option value=""></option>
          <option value="PFD OR BETTER">PFD OR BETTER</option>
          <option value="STD OR BETTER">STD OR BETTER</option>
          <option value="TABLE 2/B - 4/D">TABLE 2/B - 4/D</option>
          <option value="HIGHLY RATED">HIGHLY RATED</option>
        </select>
        {errors.targetRating && <span style={{ color: 'red' }}>{errors.targetRating.message}</span>}
      </div>
    </div>
    <div className="form-row">
      <div className="form-group">
        <label htmlFor="a1035">1035 (If Applicable)</label>
        <input id="a1035" {...register('a1035')} />
        {errors.a1035 && <span style={{ color: 'red' }}>{errors.a1035.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="survivorship">Survivorship</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value="true"
              checked={survivorshipValue === true}
              onChange={handleSurvivorshipChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              value="false"
              checked={survivorshipValue === false}
              onChange={handleSurvivorshipChange}
            />
            No
          </label>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="retentionOnly">
          Retention Only
        </label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value="true"
              checked={retentionOnlyValue === true}
              onChange={handleRetentionOnlyChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              value="false"
              checked={retentionOnlyValue === false}
              onChange={handleRetentionOnlyChange}
            />
            No
          </label>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="retentionOnly">
          Premium Finance
        </label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value="true"
              checked={premiumFinanceValue === true}
              onChange={handlePremiumFinanceChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              value="false"
              checked={premiumFinanceValue === false}
              onChange={handlePremiumFinanceChange}
            />
            No
          </label>
        </div>
      </div>
    </div>
  </div>
);

export default InformalGoalsSection;
