
import { InvoiceBackupItem } from '../../models/invoiceitem';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
  
export const exportToPDF = (invoiceItems: InvoiceBackupItem[], fileName: string) => {
  const doc = new jsPDF();

  // Add title
  doc.text("Invoice Backup Report", 14, 10);

  AddSummaryTable(invoiceItems, doc);
  AddDetailTable(invoiceItems, doc);

  // Save the PDF
  doc.save(`${fileName}.pdf`);
};

const AddDetailTable = (invoiceItems: InvoiceBackupItem[], doc: jsPDF) => {
  const headers = [
    ["Agency", "Last Name", "Policy ID", "Target Premium", "Face Amount", "Submit Date"],
  ];

  const tableData = invoiceItems.map((item) => [
    item.submitDate || "",
    item.policyId || "",
    item.agency || "",
    item.lastName || "",
    item.targetPremium || "",
    item.faceAmount || "",
  ]);

  autoTable(doc, {
    head: headers,
    body: tableData,
    startY: 40, // Start position from the top
    styles: {
      fontSize: 10, // Font size
      fillColor: [240, 240, 240], // Cell background color
    },
    headStyles: {
      fillColor: [52, 176, 191], // Header background color (blueish)
      textColor: [255, 255, 255], // Header text color (white)
      fontStyle: "bold", // Header font bold
    },
    alternateRowStyles: {
      fillColor: [249, 249, 249], // Light gray for alternate rows
    },
    bodyStyles: {
      fillColor: [255, 255, 255], // White background for other rows
    },
  });
}

const AddSummaryTable = (invoiceItems: InvoiceBackupItem[], doc: jsPDF) => {
  const headers = [
    ["Total Cost", "Total Trial Packages Received", "Total Face Amount", "Total Associated Target Premium"],
  ];

  const data = [
    [
      invoiceItems.length * 30, // Example cost per item
      invoiceItems.length,
      getTotalFace(invoiceItems),
      getTotalTargetPremium(invoiceItems),
    ],
  ];

  autoTable(doc, {
    head: headers,
    body: data,
    startY: 20, // Start position from the top
    styles: {
      fontSize: 10, // Font size
      fillColor: [240, 240, 240], // Cell background color
    },
    headStyles: {
      fillColor: [52, 176, 191], // Header background color (blueish)
      textColor: [255, 255, 255], // Header text color (white)
      fontStyle: "bold", // Header font bold
    },
    alternateRowStyles: {
      fillColor: [249, 249, 249], // Light gray for alternate rows
    },
    bodyStyles: {
      fillColor: [255, 255, 255], // White background for other rows
    },
  });
}
  
const getTotalFace = (invoiceItems: InvoiceBackupItem[]) => {
  let total = 0;
  invoiceItems.forEach((item) => {
    const faceAmount = Number(item.faceAmount);
    if (!isNaN(faceAmount)) {
      total = total + faceAmount;
    } else {
      return 0;
    }
  })

  return total;
};

const getTotalTargetPremium = (invoiceItems: InvoiceBackupItem[]) => {
  let total = 0;
  invoiceItems.forEach((item) => {
    const targetPremium = Number(item.targetPremium);
    if (!isNaN(targetPremium)) {
      total = total + targetPremium;
    } else {
      return 0;
    }
  })

  return total;
};